// this is shared mixins file for all components from the app,
// there you can easily add your own variables / imports or redefine
// existing without touching default packages for future updates

@import 'src/components/kit/core/mixins.scss'; // import KIT mixins
@import 'src/components/momunity/styles/mixins.scss'; // import CLEANUI mixins

// $text: #000;
// $success: green;

:global(.ant-breadcrumb) {
  font-size: 18px !important;

  :global(.anticon) {
    font-size: 18px !important;
  }
}

[data-kit-theme='dark'] {
  :global(.ant-table-row) {
    background-color: $dark-gray-4;
  }
}
