@import 'components/mixins.scss';

.header {
  position: fixed;
  right: 0;
  left: 270px;
  z-index: 10;
  height: 64px;
  border-right: 1px solid #00000010;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

[data-kit-theme='default'] {
  .header {
    background: #1976d2;
    color: white;

    :global(.fa.fa-sign-in) {
      color: white;
    }
  }
}

[data-kit-theme='dark'] {
  .header {
    background: $dark-gray-6;
  }
}
